.header-item {
  padding: 0.1em;
}

.header-text {
  margin: 0.1em !important;
  padding-top: 0.1em !important;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 20px;
  font-weight: bold;
}

.navbar-start {
  margin-right: 0% !important;
}