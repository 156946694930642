.gantt .bar-green .bar-progress {
	fill: green;
}

.gantt .bar-red .bar-progress {
	fill: tomato;
}

.gantt .bar-gray .bar-progress {
	fill: gray;
}

.gantt .bar-brown .bar-progress {
	fill: brown;
}

.gantt .bar-gold .bar-progress {
	fill: gold;
}

.popup-wrapper {
	visibility: hidden
}

.gantt-mode {
	padding: 0.1em;
}

.bar-label {
	font-size: 14px !important;
	font-weight: bold !important;
}