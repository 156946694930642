.Button {
  background-color: #554c4c;
  border-radius: 12px;
  margin: 5px 0px 5px 20px;
  border: 0;
  color: #fff;
  font-size: 16px;
  padding: 5px 30px;
}

.Button:hover {
  transform: scale(1.05);
}
