.Dialog {
  border: 1px solid #a0a0a0;
  border-radius: 4px;
}

.DialogModalOpen {
  overflow: hidden; /* no body scroll */
}

.Dialog .DialogHeader {
  background-color: #f3f2f1;
  color: #8c8c8c;
  font-size: 24px;
  margin: 0;
  padding: 10px 10px 10px 20px;
  text-align: left;
  font-weight: 300;
  text-transform: uppercase;
}

.Dialog .DialogBody {
  max-height: 50vh;
  overflow-x: hidden;
  padding: 10px 30px 30px 30px;
  text-align: center;
  font-size: 18px;
}

.Dialog .DialogFooter {
  padding: 10px;
  text-align: right;
  background-color: #f3f2f1;
}

.DialogModal {
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.DialogModal .DialogModalWrap {
  background: white;
  margin: 5% auto;
  width: 350px;
}

.DialogDismiss {
  background: #d9d9d9;
  color: #6b6b6b;
}

.DialogFooter .Button {
  margin: 10px;
}
