

.FormRow {
  text-align: left;
}

.FormRow input,
.FormRow textarea {
  width: 100%;
}
